<template>
  <div class="home">
    <Banner
      :title="$t('home.title')"
      sub-title=""
      :img-url="imgUrl"
      ref="Banner"
    />
    <ContentWarp>
      <p v-html="$t('home.p1')" />
    </ContentWarp>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue';
import ContentWarp from '@/components/ContentWarp.vue';

export default {
  name: 'Home',
  components: {
    Banner,
    ContentWarp,
  },
  data() {
    return {
      imgUrl: 'banner_mb_01.jpg',
    };
  },
  mounted() {
    this.onResize();
    window.onresize = () => {
      this.onResize();
    };
  },
  methods: {
    onResize() {
      this.imgUrl = window.screen.width < 768 ? 'banner_mb_01.jpg' : 'banner_pc_01.jpg';
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .home {
    padding: 0;
    min-height: calc(100vh - 240px);
  }
}
</style>
