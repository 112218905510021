import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/i18n';

Vue.config.productionTip = false;

(async () => {
  new Vue({
    i18n: await i18n(),
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
})();
