<template>
  <div id="nav">
    <div class="logo">
      <img :src="require('@/assets/logo.svg')" >
    </div>
    <div class="nav-option">
      <div class="link">
        <router-link to="/">{{ $t('home.nav') }}</router-link><span>·</span>
        <router-link to="/about">{{ $t('about.nav') }}</router-link><span>·</span>
        <router-link to="/field">{{ $t('field.nav') }}</router-link>
      </div>
      <div class="lang">
        <span class="lang-option" :class="{ active: lang === 'en' }" @click="setLanguage('en')">EN</span>
        <span class="mid">
          <div></div>
        </span>
        <span class="lang-option" :class="{ active: lang === 'cn' }" @click="setLanguage('cn')">中</span>
      </div>
      <div class="menu" :class="{ change: menuState }" @click="menuState = !menuState">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
    </div>
    <div class="sidenav-bg" :class="{ active: menuState }">
      <div class="sidenav" :class="{ active: menuState }">
        <div class="sidenav-link">
          <router-link to="/">{{ $t('home.nav') }}</router-link>
          <router-link to="/about">{{ $t('about.nav') }}</router-link>
          <router-link to="/field">{{ $t('field.nav') }}</router-link>
        </div>
        <hr>
        <div class="sidenav-lang">
          <span class="lang-option" :class="{ active: lang === 'en' }" @click="setLanguage('en')">EN</span>
          <span class="mid">
            <div></div>
          </span>
          <span class="lang-option" :class="{ active: lang === 'cn' }" @click="setLanguage('cn')">中</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setLanguage, getLanguage } from '@/i18n';

export default {
  name: 'Nav',
  data() {
    return {
      menuState: false,
      lang: getLanguage(),
    };
  },
  watch: {
    $route() {
      this.menuState = false;
    },
  },
  methods: {
    setLanguage(lang) {
      this.lang = lang;
      setLanguage(lang);
    },
  },
};
</script>

<style scoped lang="scss">
#nav {
  display: flex;
  align-items: center;
  justify-items: stretch;
  padding: 29px 30px;
  max-width: 1120px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  .logo {
    font-size: 24.5px;
    img {
      width: 291px;
      display: block;
    }
  }
  .nav-option {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    display: -webkit-box;
    -webkit-box-pack: end;
    -webkit-box-align: center;
    .link {
      padding: 0 38px;
      text-align: center;
      white-space: nowrap;
      a {
        color: #2D292A;
        text-decoration: none;
        &:hover {
          color: #666;
        }
        &:active {
          color: #D62334;
        }
        &.router-link-exact-active {
          color: #D62334;
        }
      }
      span {
        padding: 0 14px;
      }
    }
    .lang {
      display: flex;
      align-items: center;
      .lang-option {
        width: 32px;
        height: 32px;
        text-align: center;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          border-radius: 100%;
          background: #D62334;
          color: #FFFFFF;
        }
      }
      .mid {
        padding: 0 8px;
        div {
          height: 20px;
          width: 0px;
          border-left: 1px solid #C2C2C2;
        }
      }
    }
    .menu {
      display: none;
      cursor: pointer;
      .bar1, .bar2, .bar3 {
        width: 28px;
        height: 1px;
        background-color: #D62334;
        margin: 6px 0;
        transition: 0.4s;
      }
      /* Rotate first bar */
      &.change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-5px, 5px) ;
        transform: rotate(-45deg) translate(-5px, 5px) ;
      }

      /* Fade out the second bar */
      &.change .bar2 {
        opacity: 0;
      }

      /* Rotate last bar */
      &.change .bar3 {
        -webkit-transform: rotate(45deg) translate(-5px, -5px) ;
        transform: rotate(45deg) translate(-5px, -5px) ;
      }
    }
  }
  .sidenav-bg {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  html[lang=en] #nav {
    .logo {
      img {
        width: 262px;
      }
    }
    .nav-option {
      .link {
        padding: 0 30px;
        span {
          padding: 0 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 16px 15px;
    z-index: 100;
    background-color: #FFFFFF;
    .logo {
      img {
        width: 194px;
      }
    }
    .nav-option {
      display: block;
      text-align: right;
      .link, .lang {
        display: none;
      }
      .menu {
        display: inline-block;
      }
    }
    .sidenav-bg {
      display: block;
      width: 0;
      background-color: rgba(0,0,0,0);
      height: calc(100% - 60px);
      top: 60px;
      right: 0;
      position: fixed;
      z-index: 100000;
      &.active {
        width: 100%;
        background-color: rgba(0,0,0,0.8);
      }
      .sidenav {
        float: right;
        height: 100%;
        background-color: #BF2635;
        width: 0;
        overflow-x: hidden;
        transition: 0.5s;
        &.active {
          width: 250px;
        }
        .sidenav-link {
          padding: 28px 0;
          a {
            padding: 20px;
            text-decoration: none;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #650811;
            display: block;
            white-space: nowrap;
            &:active {
              color: #FFFFFF;
            }
            &.router-link-exact-active {
              color: #FFFFFF;
            }
          }
        }
        hr {
          margin: 0 auto;
          width: 210px;
          border: unset;
          border-top: 1px solid #D1304C;
        }
        .sidenav-lang {
          padding: 30px 20px;
          display: flex;
          align-items: center;
          .lang-option {
            height: 43px;
            text-align: center;
            color: #650811;
            font-weight: 600;
            font-size: 18px;
            line-height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.active {
              color: #FFFFFF;
            }
          }
          .mid {
            padding: 0 12px;
            div {
              height: 20px;
              width: 0px;
              border-left: 1px solid #D1304C;
            }
          }
        }
      }
    }
  }
  html[lang=en] #nav {
    .logo {
      img {
        width: 194px;
      }
    }
  }
}

</style>
