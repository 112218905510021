import Vue from 'vue';
import VueI18n from 'vue-i18n';
// const enLocale = require(window.location + 'lang/en/index.json')
// const cnLocale = require(window.location + 'lang/cn/index.json')

Vue.use(VueI18n);

let messages = {
  en: {},
  cn: {},
};

const setPageLanguage = (lang) => {
  document.querySelector('html').setAttribute('lang', lang);
};

export function getLanguage() {
  let lang = 'en';

  if (localStorage.getItem('language')) {
    lang = localStorage.getItem('language');
  }

  if (!messages[lang]) {
    lang = 'en';
  }
  setPageLanguage(lang);
  return lang;
}

let i18n = {};

const createI18n = async () => {
  messages = {
    en: await (await fetch(`${window.location.origin}/lang/en/index.json`)).json(),
    cn: await (await fetch(`${window.location.origin}/lang/cn/index.json`)).json(),
  };
  i18n = new VueI18n({
    locale: getLanguage(),
    messages,
  });
  return i18n;
};

export function setLanguage(val) {
  let lang = val;
  if (!messages[lang]) {
    lang = 'en';
  }
  i18n.locale = lang;
  setPageLanguage(lang);
  localStorage.setItem('language', lang);
}

export default createI18n;
