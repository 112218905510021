<template>
  <div class="content-warp">
    <div class="warp">
      <slot />
      <div class="bottom-line"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentWarp',
};
</script>

<style>
.content-warp .red {
  color: #D62334;
}
</style>
<style scoped lang="scss">
.content-warp {
  padding: 4px 20px 30px;
  background-color: #F7F7F7;
  margin-bottom: 60px;
  .warp {
    max-width: 200px;
    margin: 0 auto;
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 36px;
      margin: 36px 0 0;
      color: #2D292A;
      span {
        color: #D62334;
      }
    }
    .bottom-line {
      width: 80px;
      height: 4px;
      background: #D5B159;
      margin: 30px 0;
    }
    .content-items {
      display: flex;
      margin: 0 -25px;
      margin-top: 30px;
      .item {
        display: flex;
        align-items: center;
        padding: 21px 0 21px 3.2%;
        background: #F7F7F7;
        border: 1px solid #EDEDED;
        width: 100%;
        margin: 0 2.1%;
        div {
          .images {
            float: left;
            width: 88px;
            height: 88px;
            background: #BF2635;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('../assets/icon_watermark.png');
            background-size: auto 100%;
            background-position: left;
            background-repeat: no-repeat;
            img {
              width: 52px;
              height: 52px;
              display: block;
              filter: invert(85%) sepia(54%) saturate(0%) hue-rotate(339deg) brightness(108%) contrast(100%);
            }
          }
        }
        .label {
          font-size: 22px;
          line-height: 31px;
          color: #2D292A;
          padding: 28px 0 28px 9.2%;
        }
        &:first-child {
          div {
            .images {
              img {
                width: 42px;
                height: 42px;
              }
            }
          }
        }
      }
    }
  }
}
.home {
  .content-warp {
    .warp {
      max-width: 816px;
    }
  }
}
.about {
  .content-warp {
    .warp {
      max-width: 954px;
    }
  }
}
.field {
  .content-warp {
    padding: 0;
    margin: 0;
    background-color: transparent;
    .warp {
      max-width: 100%;
      p {
        margin: 40px 0 0;
        &:first-child {
          margin: 30px 0 0;
        }
      }
    }
  }
}

html[lang=en] .content-warp {
  .warp {
    .content-items {
      .item {
        padding: 21px 0 21px 38px;
        .label {
          font-size: 22px;
          line-height: 32px;
          padding: 0 0 0 16px;
        }
      }
    }
  }
}

html[lang=en] .home .content-warp {
  .warp {
    max-width: 894px;
  }
}

html[lang=en] .about .content-warp {
  .warp {
    max-width: 894px;
  }
}

@media screen and (max-width: 768px) {
  .content-warp {
    padding: 0 0 30px;
    margin: 0 15px 42px;
    .warp {
      max-width: unset;
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        padding: 22px 0 0;
        margin: 0;
      }
      .bottom-line {
        width: 60px;
        height: 2px;
        margin: 20px 0;
      }
      .content-items {
        flex-direction: column;
        margin: 0;
        .item {
          display: flex;
          align-items: center;
          padding: 10px 0 10px 22px;
          margin: 10px 0;
          width: unset;
          div {
            .images {
              width: 60px;
              height: 60px;
              img {
                width: 36.4px;
                height: 36.4px;
              }
            }
          }
          .label {
            font-size: 16px;
            line-height: 22px;
            padding: 29px 0 29px 28px;
          }
          &:first-child {
            div {
              .images {
                img {
                  width: 30.8px;
                  height: 30.8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .home {
    .content-warp {
      .warp {
        padding: 0 20px;
      }
    }
  }
  .about {
    .content-warp {
      .warp {
        padding: 0 19px;
      }
    }
  }
  .field {
    .content-warp {
      margin: 0 15px;
      .warp {
        p {
          padding: 12px 0 0;
          margin: 0;
          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }

  html[lang=en] .content-warp {
    padding: 0 0 20px;
    margin: 0 15px 40px;
    .warp {
      max-width: unset;
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
      }
      .content-items {
        .item {
          padding: 10px 0 10px 14px;
          .label {
            font-size: 15px;
            line-height: 21px;
            padding: 29px 0 29px 10px;
          }
          &:first-child {
            div {
              .images {
                img {
                  width: 30.8px;
                  height: 30.8px;
                }
              }
            }
          }
        }
      }
    }
  }

  html[lang=en] .home .content-warp {
    .warp {
      padding: 0 18px;
    }
  }

  html[lang=en] .field .content-warp {
    padding: 0;
    margin: 0 15px;
    .warp {
      p {
        padding: 10px 0 0;
        margin: 0;
      }
    }
  }
}
</style>
