<template>
  <div class="banner">
    <img :src="require('@/assets/' + imgUrl)" />
    <div class="warp">
      <div class="title" v-html="title"></div>
      <div class="sub-title" v-html="subTitle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    imgUrl: String,
    title: String,
    subTitle: String,
  },
};
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .warp {
    position: absolute;
    left: 15.3%;
    top: 35.1%;
    color: #FFFFFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 70px;
    }
    .sub-title {
      font-weight: 300;
      font-size: 30px;
      line-height: 42px;
    }
  }
}

html[lang=en] .banner {
  .warp {
    left: 11.7%;
    top: 34%;
  }
}

html[lang=en] .home .banner {
  .warp {
    .title {
      font-weight: 600;
      font-size: 46px;
      line-height: 64px;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner {
    margin-top: 60px;
    padding: 0 15px;
    .warp {
      left: 13.6%;
      top: 30%;
      .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
      }
      .sub-title {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  html[lang=en] .banner {
    .warp {
      left: 8.8%;
      top: 31%;
      .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
      }
      .sub-title {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  html[lang=en] .home .banner {
    .warp {
      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
}
</style>
