<template>
  <div id="footer">
    <div>COPYRIGHT © 2021&nbsp;</div>
    <div>DANCHENCAP GROUP • TERMS OF USE</div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  background: #3D3C3C;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

@media screen and (max-width: 768px) {
  #footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
